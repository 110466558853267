<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="pa-5">

    <!-- descarga y resumen -->
    <v-container class="pt-2 pb-0">
      <section class="d-flex pb-2">
        <v-text-field append-icon="search" dense outlined class="me-2" v-model="search" @keyup="buscarConFiltro($event.target.value)" label="Buscar" hide-details>
        </v-text-field>

        <v-menu offset-y class="me-2 mt-1">
          <template v-slot:activator="{ attrs, on }">
            <v-btn class="me-2 mt-1 hover-opcion" color="success" v-bind="attrs" v-on="on"
              style="height: 30px !important; padding: 0px 9px !important;">
              <p style="margin: 0px !important; padding: 0px !important; font-size: 0.75rem !important;">
                <v-icon>file_download</v-icon>Exportar resultado
              </p>
            </v-btn>
          </template>

          <v-list>
            <v-list-item style="padding: 0 0 0 8px;" v-if="itemCotizacion.incluirCotizacion ">
              <v-list-item-title class="py-1" style="cursor: pointer;" @click="descargarPdfCotizacion()">
                <v-icon color="error" class="me-2">picture_as_pdf</v-icon>PDF
              </v-list-item-title>
            </v-list-item>
            <v-list-item style="padding: 0 0 0 8px; cursor: pointer;" v-if="itemCotizacion.incluirCotizacion">
              <v-list-item-title class="py-1" style="cursor: pointer;" @click="descargarExcelCotizacion()">
                <v-icon color="success" class="me-2">description</v-icon>Excel
              </v-list-item-title>
            </v-list-item>
            <v-list-item style="padding: 0 0 0 8px; cursor: pointer;" v-if="!itemCotizacion.incluirCotizacion">
              <v-list-item-title class="py-1" style="cursor: pointer;" @click="descargarPlanoCotizacion()">
                <v-icon color="success" class="me-2">description</v-icon>CSV
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn @click="dialogConfirmacion.estado = true" v-if="itemCotizacion.estado" small color="primary" dark class="me-2 mt-1">
          Bloquear registro
        </v-btn>

      <v-btn @click="dialogVerResumen" small color="primary" dark class="me-2 mt-1">
        Ver resumen
      </v-btn>

      <v-btn small color="primary" dark class="me-2 mt-1">
        <a href="#/modules/tools/homologator/historico-homologacion" style="color: white; text-decoration:none">Regresar histórico</a>
      </v-btn>

    </section>

    <!-- Tabla homologacion -->
    <section class="tabla-tools">
      <v-data-table :headers="headers" :server-items-length="itemCotizacion.productosTotales"
        :items="productosHomologados" :loading="cargandoDatos" :items-per-page="detallesPaginado.itemsPorPagina"
        :page="detallesPaginado.pagina" @update:items-per-page="(i) => detallesPaginado.itemsPorPagina = i"
        @update:page="(p) => detallesPaginado.pagina = p" :footer-props="footerProps" height="66vh" fixed-header>

        <template v-slot:body="{ items }">

          <tbody class="este-con-clase">
            <tr v-for="element in items" :class="colorFondoPorItem(element)" :key="element.stone">
              <td>
                <v-checkbox :readonly="!itemCotizacion.estado" class="modificado-tabla" hide-details=true
                  @change="recargar(element.id)" v-model="element.modificado">
                </v-checkbox>
              </td>
              <td v-if="validarHeader('nombreGenerico')" style="max-width: 10vh !important">
                {{ element.nombreGenerico }}
              </td>
              <td v-if="validarHeader('nombreComercial')" style="max-width: 10vh !important;">
                {{ element.nombreComercial }}
              </td>
              <td v-if="validarHeader('cums')" style="max-width: 10vh !important;">
                {{ element.cums }}
              </td>
              <td v-if="validarHeader('laboratorio')" style="max-width: 10vh !important;">
                {{ element.laboratorio }}
              </td>
              <td style="min-width: 70vh !important; max-width: 70vh !important;">

                <span title="Producto Cohan | Stone | Existencias | Precio">

                  <v-autocomplete :readonly="!itemCotizacion.estado" class="seleccionar-multiple" hide-details=true
                    multiple :items="element.portafolio" v-model="element.model" dense filled
                    :item-text="obtenerNombreItem" return-object>

                    <template v-slot:item="{ item }">
                      <v-list-item-content @click="handlerChangeModificado(item, element)">
                        <v-checkbox v-model="item.seleccionado" single-line hide-details
                          :label="obtenerNombreItem(item)"></v-checkbox>
                      </v-list-item-content>
                    </template>

                  </v-autocomplete>

                </span>

              </td>
              <td class="text-center">
                <v-btn hide-details=true :disabled="!itemCotizacion.estado" small
                  @click="handlerHomologarManual(element.id)" :key="items.indexOf(element)" depressed color="primary">
                  ...
                </v-btn>
              </td>

            </tr>
          </tbody>

        </template>
        <template v-slot:footer.page-text="items">
          {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
        </template>
      </v-data-table>

    </section>
  </v-container>

    <!-- Homologacion manual -->
    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title class="blueGlobal">
          <v-row>
            <v-col class="d-flex" style="float: left; font-size: 0.8rem !important; font-weight: normal;" cols="11">
              <span v-show="itemCotizacion.nombreGenerico" style="padding-right: 8px;"><strong>Genérico:</strong> {{
                nombreEncabezadoHomogacion('nombreGenerico')
              }}</span>
              <span v-show="itemCotizacion.nombreComercial" style="padding-right: 8px;"><strong>Comercial:</strong> {{
                nombreEncabezadoHomogacion('nombreComercial')
              }}</span>
              <span v-show="itemCotizacion.cums" style="padding-right: 8px;"><strong>Cum:</strong> {{
                nombreEncabezadoHomogacion('cums')
              }}</span>
              <span v-show="itemCotizacion.laboratorio"><strong>Laboratorio:</strong> {{
                nombreEncabezadoHomogacion('laboratorio')
              }}</span>
            </v-col>
            <v-col>
              <v-btn style="float: right;" color="white" text @click="dialog = false">
                <v-icon>close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <keep-alive>
            <component ref="homologadorManualModel" :listaPrecioCodigo="itemCotizacion.listaPrecio"
              :incluirCotizacion="itemCotizacion.incluirCotizacion" :is="componenteActual"
              :tipoPortafolioId="resultadosHomologacion.tipoPortafolioId"
              @handlerPortafolioManual="handlerPortafolioManual">
            </component>
          </keep-alive>
        </v-card-text>

      </v-card>
    </v-dialog>

    <!-- Dialogo de confirmacion -->
    <v-dialog v-model="dialogConfirmacion.estado" max-width="360">
      <v-card>
        <v-card-title class="fondoHeader">
          {{ dialogConfirmacion.head }}
        </v-card-title>
        <div class="d-flex justify-end pa-3">
          <v-btn color="success" @click="bloquearCotizacion">{{ dialogConfirmacion.textoConfirmar }}</v-btn>
          <v-btn color="error" text @click="dialogConfirmacion.estado = false">{{ dialogConfirmacion.textoCancelar
          }}</v-btn>
        </div>
      </v-card>
    </v-dialog>

    <!-- Dialog ver resumen -->
    <ResumenHomologacion ref="resumenHomologacion"></ResumenHomologacion>
  </div>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de scripts                                                     ###### -->
<!-- #################################################################################### -->

<script>
import { mapMutations, mapState } from "vuex";
import moment from "moment";
import HomologarManual from './HomologarManual.vue'
import ResumenHomologacion from './ResumenHomologacion.vue'

const debounce = function debounce(fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    let args = arguments;
    let that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};

export default {
  name: 'HomologadorTabla',
  props: {},
  mounted() {
    this.cargandoDatos = true
  },
  data() {
    // SE CREA EL FORMULARIO PARA LA HOMOLOGACION CON LOS CAMPOS REQUERIDOS
    const formularioHomologador = {
      errors: [],
      generico: false,
      comercial: false,
      laboratorio: false,
      cums: false,
      nit: ''
    };

    const formularioPortafolio = {
      msg: [],
      status: false,
      file: null
    }
    return {
      // encargado de abrir el dialogo de confirmacion
      dialogConfirmacion: {
        estado: false,
        head: '¿Bloquear registro?',
        textoConfirmar: 'Si',
        textoCancelar: 'No',
      },
      detallesPaginado: {
        pagina: 1,
        itemsPorPagina: 10
      },
      cargandoDatos: false,
      itemCotizacion: {
        usuario: '',
        laboratorio: false,
        nombreComercial: false,
        nombreGenerico: false,
        cums: false
      },
      userRoles: {},
      formularioHomologador,
      ocultarTabla: true,
      verMenu: true,
      formularioPortafolio,
      //cambio de componente
      componenteActual: 'Dummy',
      itemPortafolioId: null,
      // dialogo 
      dialog: false,
      //variable necesaria para el filtro de la tabla
      search: '',
      // filtro tabla homologacion
      filtroHomologacion: '',
      text: '',
      timeout: 3000,
      color: 'blue',
      //versionado del portafolio
      portafolioVersion: {
        fechaVersion: null,
        version: null
      },
      items: [
        'Homologador'
      ],
      csvHeadersInicio: ['Nombre genérico cliente', 'Nombre comercial cliente', 'Nombre laboratorio cliente'],
      csvHeadersFin: ['Stone', 'Nombre', 'Buffer'],
      exportAvailable: false,
      currentStatus: null,
      headers: [
        { text: '', value: 'id', sortable: false, width: '1%' },
        { text: 'PRODUCTOS COHAN', value: 'portafolio', sortable: false },
        { text: 'PORTAFOLIO', value: 'id', sortable: false, }
      ],
      footerProps: {
        showFirstLastPage: true,
        "show-current-page": true,
        'items-per-page-text': 'Ítems por página',
        'items-per-page-options': [10, 20],
        'items-length': 100
      },
      productosHomologados: [],
      resultadosHomologacion: {
        totalTime: 0,
        totalProductosCliente: 0,
        totalHomologado: 0,
        totalFaltante: 0,
        efectividad: 0,
        tipoPortafolio: '',
        tipoPortafolioId: '',
      },
      homologacionReady: false,
      foco: false,
      interval: null,
      offsetHeigth: 500,
    }
  },
  created() {
    this.userRoles = this.auth.roles;
  },
  computed: {
    ...mapState(["auth", "notify", "busy"]),
  },
  methods: {
    ...mapMutations([
      "updateAuth",
      "hideNotify",
      "showSuccess",
      "showBusy",
      "hideBusy",
      "snackbar",
    ]),
    // ocultar o ver el header de la tabla
    validarHeader(header) {
      const validar = this.headers.some(item => item.value == header);
      return validar
    },
    // funcion necesaria para recargar la lista de productosHomologados y ver reflejado en el cambio del color de la fila
    bloquearCotizacion() {
      const id = this.itemCotizacion.id;
      this.$http.post(`msa-tools/api/item-cotizacion/bloquear/${id}`).then(() => {
        this.itemCotizacion.estado = false;
        this.snackbar('Cotización bloqueada con éxito', 'success', 2000);
        this.dialogConfirmacion.estado = false;
      }).catch(error => {
        console.error(error)
        this.snackbar('Error al bloquear la cotización', 'error', 2000);
        this.dialogConfirmacion.estado = false;
      })
    },
    recargar(id = null) {
      if (id) {
        this.$http.post(`msa-tools/api/item-cotizacion-detalle/modificado/${id}`).then((response) => {
          this.productosHomologados = this.productosHomologados.map(item => {
            if (item.id == id) item.modificado = response.data;
            return item;
          });
        }).catch(error => {
          console.error(error)
          this.productosHomologados.forEach(element => {
            if (element.id == id) element.modificado = false;
          });
          this.snackbar('Error al cambiar el estado del elemento', 'error', 2000)
        })
      }
    },
    //cambio de clase necesaria para el cambioo de color por la fila
    colorFondoPorItem(item) {
      return (item.modificado) ? 'fila-seleccionada' : '';
    },
    //funcionar encargada de cambiar el estado "modificado" de cada itemCotizacionDetallePortafolio
    handlerChangeModificado: function (portafolioSeleccionado, itemCotizacionDetalle) {
      const icdpId = portafolioSeleccionado.icdpId;
      this.$http.post(`msa-tools/api/item-cotizacion-detalle-portafolio/modificado/${icdpId}`).then((response) => {
        if (!itemCotizacionDetalle.modificado) {
          this.productosHomologados = this.productosHomologados.map(element => {
            if (itemCotizacionDetalle.id == element.id) {
              let retorno = [];
              retorno = element.model.map(e => {
                if (e.icdpId == icdpId) e.seleccionado = response.data;
                return e;
              })
              element.model = retorno;
            }
            return element;
          });
          this.recargar(itemCotizacionDetalle.id);
        }
      }).catch(error => {
        console.error(error)
        this.productosHomologados = this.productosHomologados.map(element => {
          if (itemCotizacionDetalle.id == element.id) {
            let retorno = [];
            retorno = element.model.map(e => {
              if (e.icdpId == icdpId) e.seleccionado = false;
              return e;
            })
            element.model = retorno;
          }
          return element;
        });
        this.recargar(itemCotizacionDetalle.id);
        this.snackbar('Error al cambiar el estado del elemento', 'error', 2000)
      })
    },
    //funcion necesaria para obtener el nombre completo del portafolio
    obtenerNombreItem(item) {
      if (item) {
        const detalleStone = item.detalleStone;
        const existencias = item.existencias;
        let exisMedellin = 0;
        let auxiliarPrincipal = '';
        let auxiliarDetalleStone = (this.itemCotizacion.incluirCotizacion) ? 'Producto no relacionado con la lista' : '';
        if (existencias) {
          exisMedellin = item.existencias.find(element => element.codBode == 10103)?.uniDisp || 0;
        }
        if (detalleStone) {
          const porcentajeImpuesto = detalleStone.porImpu;
          const impuesto = (detalleStone.valPrec * porcentajeImpuesto) / 100;
          const precioRedondeado = Math.ceil(detalleStone.valPrec + impuesto);
          auxiliarDetalleStone = `$ ${precioRedondeado}`;
        }
        const textoExistencias = `Medellín: ${exisMedellin} `;
        return `${auxiliarPrincipal} ${item.nombre} | ${item.stone} | ${textoExistencias} | ${auxiliarDetalleStone} `
      }
    },
    //se obtiene el portafolio enviado desde el hijo
    handlerPortafolioManual: async function (portafolios) {
      this.dialog = false;
      this.componenteActual = 'Dummy';
      // se obtiene el item cotizacion detalle seleccionado
      const detalleSeleccionado = this.productosHomologados.find((item) => item.id == this.itemPortafolioId);
      // se filtra los portafolios de ese item cotizacion
      const portafoliosFaltantes = portafolios.filter(element => {
        const banderaPortafolio = detalleSeleccionado.portafolio.some(porta => porta.id == element.id);
        if (!banderaPortafolio) {
          return true;
        }
      })
      // se almacenan todos los portafolios nuevos
      await Promise.all(portafoliosFaltantes.map(faltante => this.postAgregarPortafolio(faltante)));
      // se llama nuevamente a la api y recargamos todo el objeto 
      this.getCotizacion();
    },
    postAgregarPortafolio(portafolio) {
      return new Promise((resolve, reject) => {
        this.$http.post(`msa-tools/api/item-cotizacion-detalle/${this.itemPortafolioId}`, {
          usuario: this.auth.username,
          portafolioId: portafolio.id
        }).then(response => {
          return resolve(response.data);
        }).catch(error => {
          const err = error?.response?.data;
          const mensaje = Object.keys(err).map(element => `${element}: ${err[element]}`).join('\n');
          console.error(mensaje)
          this.snackbar('Error al agregar el producto Cohan', 'error', 2000)

          // se elimina el elemento agregado 
          this.productosHomologados = this.productosHomologados.map(homologado => {
            const { model: modelo, ...resto } = homologado;
            let model = []
            modelo.forEach(element => {
              if (element.icdpId != null && element.icdpId != undefined) {
                model.add(element);
              }
            })
            return {
              ...resto,
              model
            }
          })
          return reject('Error al adicionar el portafolio ');
        })
      })
    },
    handlerHomologarManual(id) {
      this.itemPortafolioId = id;
      this.dialog = true;
      this.componenteActual = 'HomologarManual';
    },
    // obtiene el nombre del campo que se esta homologando
    nombreEncabezadoHomogacion(parametro = '') {
      const retorno = this.productosHomologados.find(element => element.id == this.itemPortafolioId);
      if (retorno) return retorno[parametro];
      return '';
    },
    validarPortafolioYModel() {
      // se recorren los productos enviados
      this.productosHomologados = this.productosHomologados.map(element => {
        // Si el itemCotizacion cuenta con portafolios se le asigna al primer portafolio como el objeto seleccionado
        if (element.portafolio == null) {
          element.portafolio = [];
        }
        if (element.model == null) {
          element.model = [];
        }

        // se cuenta los itemCotizacion que tengan mas de 1 portafolio y se incrementa el numero
        if (element.portafolio.length > 0) { this.resultadosHomologacion.totalHomologado++; }
        const { model, ...resto } = element;

        return {
          model,
          ...resto
        }
      });
    },
    limpiarProductosSinListaPrecio(itemCotizacion){
      if(!itemCotizacion) return;
      const retorno = itemCotizacion.map(element=>{
        const { model, portafolio, ...resto } = element;
        let modelRetorno = [];
        let portafolioRetorno = [];
        if(model){
          modelRetorno = model.map(item=>{
            if(item?.detalleStone){
              return item
            }
          }).filter(item=> item)
        }
        if( portafolio ){
          portafolioRetorno = portafolio.map(item=>{
            if(item?.detalleStone){
              return item
            }
          }).filter(item=> item)
        }
        return {
          ...resto,
          model: modelRetorno,
          portafolio: portafolioRetorno
        }
      })
      return retorno;
    },
    recibirInformacion(result, time) {
      this.cargandoDatos = false
      let totalHomologado = 0;
      if(result.data.incluirCotizacion){
        this.productosHomologados = this.limpiarProductosSinListaPrecio(result.data.itemCotizacion)
      }else {
        this.productosHomologados = result.data.itemCotizacion
      }
      this.itemCotizacion = result.data;

      this.mapearHeaders();
      this.validarPortafolioYModel();

      this.resultadosHomologacion.totalProductosCliente = result.data.itemCotizacion.length;
      this.resultadosHomologacion.totalFaltante = result.data.itemCotizacion.length - this.resultadosHomologacion.totalHomologado;
      this.resultadosHomologacion.efectividad = (100 * totalHomologado / result.data.itemCotizacion.length).toFixed(2);
      this.resultadosHomologacion.totalTime = time;
      this.resultadosHomologacion.tipoPortafolio = result.data.tipoPortafolio;
      this.resultadosHomologacion.tipoPortafolioId = Number(result.data.tipoPortafolioId);

      if(!result.data.incluirCotizacion) {
        this.footerProps['items-per-page-options'] = [10, 20, 500];
      }

    },
    boleanoATexto(valor) {
      return valor ? 'Si' : 'No'
    },
    mapearHeaders() {

      if (this.itemCotizacion.nombreGenerico) this.headers = generico(this.headers);
      if (this.itemCotizacion.nombreComercial) this.headers = comercial(this.headers);
      if (this.itemCotizacion.laboratorio) this.headers = laboratorio(this.headers);
      if (this.itemCotizacion.cums) this.headers = cums(this.headers);

      function generico(headers) {
        const primerHeader = headers[0];
        let tmpHeaders = headers.filter((element) => element.text != '')
        tmpHeaders = [
          { text: 'NOMBRE GENERICO', value: 'nombreGenerico', sortable: false }
        ].concat(tmpHeaders);
        return [primerHeader].concat(tmpHeaders);
      }
      function comercial(headers) {
        const primerHeader = headers[0];
        let tmpHeaders = headers.filter((element) => element.text != '')
        tmpHeaders = [
          { text: 'NOMBRE COMERCIAL', value: 'nombreComercial', sortable: false }
        ].concat(tmpHeaders);
        return [primerHeader].concat(tmpHeaders);
      }
      function laboratorio(headers) {
        const primerHeader = headers[0];
        let tmpHeaders = headers.filter((element) => element.text != '')
        tmpHeaders = [
          { text: 'LABORATORIO', value: 'laboratorio', sortable: false }
        ].concat(tmpHeaders);
        return [primerHeader].concat(tmpHeaders);
      }
      function cums(headers) {
        const primerHeader = headers[0];
        let tmpHeaders = headers.filter((element) => element.text != '')
        tmpHeaders = [
          { text: 'CUMS', value: 'cums', sortable: false }
        ].concat(tmpHeaders);
        return [primerHeader].concat(tmpHeaders);
      }
    },
    formatoFecha(valor) {
      return moment(valor).format("YYYY/MM/DD HH:mm")
    },
    dialogVerResumen() {
      this.$refs.resumenHomologacion.recibirInformacion(this.itemCotizacion);
    },
    getCotizacion() {
      this.productosHomologados = [];
      this.cargandoDatos = true
      const { id } = this.itemCotizacion;
      const { pagina, itemsPorPagina } = this.detallesPaginado;
      this.$http.get(`msa-tools/api/item-cotizacion/${id}`, {
        params: {
          page: pagina,
          limit: itemsPorPagina,
          search: this.search
        }
      }).then(response => {
        if(response.data.incluirCotizacion){
          this.productosHomologados = this.limpiarProductosSinListaPrecio(response.data.itemCotizacion)
        }else {
          this.productosHomologados = response.data.itemCotizacion
        }
        this.validarPortafolioYModel();
        this.cargandoDatos = false
      }).catch(error => {
        console.error(error)
        this.cargandoDatos = false
      })
    },
    descargarPdfCotizacion() {
      if(this.itemCotizacion.estado) {
        this.snackbar('Para descargar el archivo PDF, es necesario bloquear el registro.', 'info', 2000)
        return;
      }
      this.$http.get(`msa-tools/api/item-cotizacion/descargar/${this.itemCotizacion.id}`, {
        responseType: 'blob'
      }).then(response => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `cotizacion_${this.itemCotizacion.id}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }).catch(error => {
        console.error(error);
        this.snackbar('Error al exportar el archivo PDF', 'error', 2000)
      })
    },
    descargarExcelCotizacion() {
      if(this.itemCotizacion.estado) {
        this.snackbar('Para descargar el archivo EXCEL, es necesario bloquear el registro.', 'info', 2000)
        return;
      }
      this.$http.get(`msa-tools/api/item-cotizacion/descargar/excel/${this.itemCotizacion.id}`, {
        responseType: 'blob'
      }).then(response => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `cotizacion_${this.itemCotizacion.id}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }).catch(error => {
        console.error(error);
        this.snackbar('Error al exportar el archivo Excel', 'error', 2000)
      })
    },
    /**
     * Metodo encargado de obtener el csv de la homologacion
     */
    descargarPlanoCotizacion() {
      if(this.itemCotizacion.estado) {
        this.snackbar('Para descargar el archivo CSV, es necesario bloquear el registro.', 'info', 2000)
        return;
      }
      this.$http.get(`msa-tools/api/item-cotizacion/descargar/csv/${this.itemCotizacion.id}`, {
        responseType: 'blob'
      }).then(response => {
        const utf8Data = new Uint8Array([0xEF, 0xBB, 0xBF]); 
        const dataWithBom = new Blob([utf8Data, response.data], { type: 'application/csv;charset=UTF-8' });
        const href = URL.createObjectURL(dataWithBom);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `homologacion_${this.itemCotizacion.id}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }).catch(error => {
        console.error(error);
        this.snackbar('Error al exportar el archivo csv', 'error', 2000)
      })
    },
    buscarConFiltro: debounce(function (e) {
      this.search = e
      this.getCotizacion();
    }, 500)
  },
  components: {
    HomologarManual,
    ResumenHomologacion,
    Dummy: {
      template: "<p>&nbsp;</p>",
      data: function () { return {}; }
    },
  },
  watch: {
    'dialog': function () {
      if (!this.dialog) {
        this.$refs.homologadorManualModel.reiniciarFormulario(true);
      }
    },
    'detallesPaginado.pagina': function () {
      this.getCotizacion();
    },
    'detallesPaginado.itemsPorPagina': function () {
      this.getCotizacion();
    },
  }
}
</script>


<!-- #################################################################################### -->
<!-- ###### Sección de estilos                                                     ###### -->
<!-- #################################################################################### -->

<style scoped>
.fila-seleccionada {
  background-color: #d2f3d5 !important;
}

::v-deep .v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}

.v-list-item--dense .v-list-item__content,
.v-list--dense .v-list-item .v-list-item__content {
  padding: 0px;
}

::v-deep .v-list-item {
  min-height: 0px !important;
}

::v-deep .v-input.v-input--hide-details.theme--light.v-input--selection-controls.v-input--checkbox {
  width: 100% !important;
}

::v-deep .v-list-item.v-list-item--link.theme--light {
  padding: 0px !important;
}

/* Dialogo de confirmacion */
.fondoHeader {
  background-color: #1867c0 !important;
  color: white;
}</style>