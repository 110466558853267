<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
   <v-row justify="center">
      <v-dialog v-model="dialogResumen" max-width="600px">

         <v-card>
            <v-card-title style="background: #0D47A1!important;">
               <span class="text-h5" style="color: white !important;">Resumen homologación</span>
            </v-card-title>
            <v-card-text>
               <v-container>
                  <v-row class="pb-0 mb-0">
                     <v-col cols="12" sm="6" md="6">
                        <v-text-field label="Homologado por" :value="usuario" readonly></v-text-field>
                     </v-col>
                     <v-col cols="12" sm="6" md="6">
                        <v-text-field label="Fecha de creación" :value="fechaCreacion" readonly></v-text-field>
                     </v-col>

                     <v-col cols="12">
                        <v-text-field label="Nombre" :value="nombre" readonly></v-text-field>
                     </v-col>

                     <v-col cols="6">
                        <v-text-field label="Incluir cotización" :value="incluirCotizacion" readonly></v-text-field>
                     </v-col>

                     <v-col cols="6">
                        <v-text-field label="Tipo portafolio" :value="tipoPortafolio" readonly></v-text-field>
                     </v-col>

                     <v-col cols="12" sm="6" md="3">
                        <v-text-field label="Nombre generico" :value="nombreGenerico" readonly></v-text-field>
                     </v-col>
                     <v-col cols="12" sm="6" md="3">
                        <v-text-field label="Nombre comercial" :value="nombreComercial" readonly></v-text-field>
                     </v-col>
                     <v-col cols="12" sm="6" md="3">
                        <v-text-field label="Laboratorio" :value="laboratorio" readonly></v-text-field>
                     </v-col>
                     <v-col cols="12" sm="6" md="3">
                        <v-text-field label="Cums" :value="cums" readonly></v-text-field>
                     </v-col>

                     <v-col cols="12">
                        <v-divider></v-divider>
                     </v-col>

                     <v-col cols="12" sm="12" md="12">
                        <v-text-field label="Nombre del cliente" :value="nombreCliente" readonly></v-text-field>
                     </v-col>

                     <v-col cols="12" sm="6" md="6">
                        <v-text-field label="NIT" :value="nit" readonly></v-text-field>
                     </v-col>

                     <v-col cols="12" sm="6" md="6">
                        <v-text-field label="Municipio del cliente" :value="ciudad" readonly></v-text-field>
                     </v-col>

                     <v-col cols="12" sm="6" md="6">
                        <v-text-field label="Dirección del cliente" :value="direccion" readonly></v-text-field>
                     </v-col>

                     <v-col cols="12" sm="6" md="6">
                        <v-text-field label="Teléfono del cliente" :value="telefono" readonly></v-text-field>
                     </v-col>

                     <v-col cols="12">
                        <v-divider></v-divider>
                     </v-col>

                     <v-col cols="12" sm="6" md="6">
                        <v-text-field label="Productos totales" :value="productosTotales" readonly></v-text-field>
                     </v-col>
                     <v-col cols="12" sm="6" md="6">
                        <v-text-field label="Productos homologados" :value="productosHomologados" readonly></v-text-field>
                     </v-col>

                     <v-col cols="12" sm="6" md="4" class="pb-0 mb-0" hide-details="auto">
                        <v-text-field label="Tiempo"
                           :value="`${duracion} ${Number(duracion) > 1 ? 'Segundos' : 'Segundo'}`" readonly></v-text-field>
                     </v-col>
                     <v-col cols="12" sm="6" md="4" class="pb-0 mb-0" hide-details="auto">
                        <v-text-field label="Productos no homologados" :value="productosNoHomologados"
                           readonly></v-text-field>
                     </v-col>
                     <v-col cols="12" sm="6" md="4" class="pb-0 mb-0" hide-details="auto">
                        <v-text-field label="Efectividad" :value="`${efectividad}%`" readonly></v-text-field>
                     </v-col>

                  </v-row>
               </v-container>
            </v-card-text>
            <v-card-actions class="justify-end">
               <v-btn color="red darken-1" text @click="dialogResumen = false">Cerrar</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </v-row>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de scripts                                                     ###### -->
<!-- #################################################################################### -->

<script>
import moment from 'moment';

export default {
   props: {
   },
   data: () => ({
      dialogResumen: false,
      id: '',
      usuario: '',
      fechaCreacion: '',
      nombreGenerico: '',
      nombreComercial: '',
      cums: '',
      laboratorio: '',
      productosTotales: '',
      productosHomologados: '',
      duracion: '',
      productosNoHomologados: '',
      efectividad: '',
      nombre: '',
      tipoPortafolio: '',
      nit: '',
      nombreCliente: '',
      ciudad: '',
      direccion: '',
      telefono: '',
      incluirCotizacion: ''
   }),
   created() {
   },
   computed: {},
   methods: {
      recibirInformacion(props) {
         this.dialogResumen = true;

         this.usuario = props.usuario;
         this.fechaCreacion = this.formatoFecha(props.fechaCreacion);
         this.nombreGenerico = this.boleanoATexto(props.nombreGenerico);
         this.nombreComercial = this.boleanoATexto(props.nombreComercial);
         this.cums = this.boleanoATexto(props.cums);
         this.laboratorio = this.boleanoATexto(props.laboratorio);
         this.nombre = props.nombre
         this.productosHomologados = props.productosHomologados;
         this.duracion = props.segundos;

         this.productosTotales = props.productosTotales;
         this.productosNoHomologados = props.productosNoHomologados;
         this.efectividad = props.efectividad;
         this.tipoPortafolio = props.tipoPortafolio;
         this.direccion = props.direccion;
         this.telefono = props.telefono;
         this.nombreCliente = props.nombreCliente;
         this.ciudad = props.municipio;
         this.nit = props.nit;
         this.id = props.id;
         this.incluirCotizacion = this.boleanoATexto(props.incluirCotizacion);

      },
      boleanoATexto(valor) {
         return valor ? 'Si' : 'No'
      },
      formatoFecha(valor) {
         return moment(valor).format("YYYY/MM/DD HH:mm")
      }
   }
}
</script>


<!-- #################################################################################### -->
<!-- ###### Sección de estilos                                                     ###### -->
<!-- #################################################################################### -->

<style scoped>
.boton-tools {
   padding: 0px 10px 0px 10px !important;
}

::v-deep .v-text-field__details {
   display: none !important;
}
</style>